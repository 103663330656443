:root {
  --main-color: #3D83C9;
  --main-color-gradient: linear-gradient(180deg, #B5D7F9 0%, #3D83C9 100%);
  --primary-color: #700061;
  --primary-color-gradient: linear-gradient(180deg, #D828C1 0%, #700061 100%);
  --background: linear-gradient(180deg, #D828C1 50%, #700061 50%);
}
@import url('https://fonts.googleapis.com/css2?family=Aleo:ital,wght@0,100..900;1,100..900&display=swap');
body, html {
  font-family: "Aleo", serif;
}
a {
  text-decoration: none;
  color: inherit;
}
.header {
  background: var(--main-color-gradient);
  position: relative;
}
.paading-0 {
  padding: 0;
}
ul.navbar-nav li {
  margin: 0px 7px;
}
nav.navbar.navbar-expand-lg {
  width: 100%;
  padding: 0;
  font-weight: 800;
  background: #fff;
}
.navbar-expand-lg .navbar-collapse {
  align-items: center;
  justify-content: center;
  font-size: 18px;
}
.navbar-expand-lg .navbar-nav .nav-link {
  color: #000000 !important;
  text-transform: uppercase;
  padding: 10px 6px;
  font-size: 15px;
}
.navbar-expand-lg .navbar-nav .public_dis a {
  color: #000 !important;
  font-weight: 800;
}
.dark-box {
  display: flex;
  text-align: center;
}
.dark-box a:hover {
  color: inherit;
}
.dark-box img {
  margin: auto;
  width: 49px;
  margin-bottom: 17px;
  display: block;
}
.dark-box>div {
  background: linear-gradient(315deg, #c121ac 50%, #D828C1 50%);
  padding: 13px 20px;
  margin: 0 10px;
  min-width: 130px;
  color: #fff;
}
.pphelp {
  z-index: 99;
  position: fixed;
  right: 0;
  bottom: 0;
  transform: translate(-0%, -20%);
  width: max-content;
}
.highlights marquee .d-flex a.url-highlight {
  color: #fff;
  margin-top: 20px;
  text-transform: uppercase;
  background: var(--primary-color-gradient);
  padding: 4px 6px 2px;
  border-radius: 6px;
}
.header_list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.header_list ul li {
  margin: 11px 10px;
  font-size: 18px;
}
.header_list ul li i {
  margin-right: 5px;
  font-size: 19px;
}
.header_list ul li a {
  text-decoration: none;
  color: #000;
}
.dark-box {
  text-transform: uppercase;
}
.highlights {
  background: var(--main-color-gradient);
  height: 100%;
  padding: 40px
}
.highlights marquee {
  height: 339px;
}
.padding-0 {
  padding: 0;
}
ul.navbar-nav li img {
  width: 40px;
  margin-right: 5px;
}
ul.navbar-nav li a {
  display: flex;
  align-items: center;
}
.highlights marquee .d-flex {
  align-items: flex-start;
  justify-content: space-between;
}
.highlights marquee .d-flex .attachments-highlight {
  background: var(--primary-color-gradient);
  display: flex;
  color: #fff;
  width: 33px;
  height: 33px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  transform: rotate(45deg);
}
.highlights marquee .d-flex>div {
  width: 80%;
}
.highlights marquee p {
  margin-bottom: 0;
}
.highlights marquee > div {
  margin-bottom: 20px;
}
.highlights marquee p span {
  font-size: 80%;
  text-transform: uppercase;
  color: var(--primary-color);
}
.highlights marquee h4 {
  text-transform: uppercase;
  font-size: 19px;
}
.highlights h2 {
  font-weight: 900;
}
.highlights h2 span {
  float: right;
}
.flex-block img {
  width: 70px;
  margin: auto;
  display: block;
}
.flex-block {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -50px;
  flex-wrap: wrap;
}
.flex-block>div {
  min-width: 210px;
  text-align: center;
  display: flex;
  flex-direction: column;
  margin: 10px;
  border-radius: 9px;
  position: relative;
}
.flex-block>div::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 17px;
  background: url(https://webapi.entab.info/api/image/NCSR/public/Images/shadow.png);
  background-size: 100% 100%;
  z-index: 1;
}
.bg-wt {
  padding: 40px 0;
  background: #fff;
  position: relative;
  z-index: 9;
  box-shadow: 0px 0px 5px 0px #a1a1a138;
  border-radius: 13px;
  position: relative;
}
.bg-wt:before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  width: 100%;
  height: 10px;
  border-radius: 9px;
  background: var(--primary-color-gradient);
  transition: 0.5s;
}
.bg-wt:hover:before {
  height: 100%;
}
.bg-wt:hover img,
.bg-wt:hover h5 {
  color: #fff;
  position: relative;
}
.title p {
  color: #737D87;
}
.title h2 {
  color: #0E2C4A;
}
.aboutsec {
  background: url(https://webapi.entab.info/api/image/NCSR/public/Images/about-bg.png);
  background-size: 100% 100%;
  padding: 80px 0;
}
.title p {
  text-transform: uppercase;
  margin-bottom: 0;
  letter-spacing: 2px;
}
.title h2 {
  font-weight: 900;
  text-transform: uppercase;
}
button.readMore {
  padding: 11px 30px;
  border-radius: 5px;
  border: 0;
  background: var(--primary-color-gradient);
  color: #fff;
  position: relative;
}
button.readMore:before {
  content: "";
  width: 10px;
  height: 80px;
  background: #fff3;
  position: absolute;
  left: 7px;
  top: -14px;
  transform: rotate(32deg);
}
button.readMore:after {
  content: "";
  width: 10px;
  height: 80px;
  background: #fff3;
  position: absolute;
  left: 7px;
  top: -42px;
  transform: rotate(32deg);
}
.kindergarten {
  background: url(https://webapi.entab.info/api/image/NCSR/public/Images/kindergartenbg.png);
  background-size: 100% 100%;
  padding: 40px 0;
}
.kinder.title {
  background: url(https://webapi.entab.info/api/image/NCSR/public/Images/title-bg.png);
  min-width: max-content;
  background-size: auto 100%;
  padding: 60px 0;
  width: max-content;
  max-width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  margin: auto
}
.position-rel {
  position: relative;
}
.kinder-strip {
  background: url(https://webapi.entab.info/api/image/NCSR/public/Images/kinder-strip.png);
  background-size: 100% 100%
}
.kinder.title h2 {
  margin-bottom: 0;
  background: #fff;
}
img.kinder-icon {
  position: absolute;
  top: -20px;
  left: -15px;
}
.kinder-content {
  text-align: center;
}
img.kinder-icon-left {
  position: absolute;
  width: 60px;
  bottom: 0;
  left: 0;
}
img.kinder-icon-right {
  position: absolute;
  width: 60px;
  bottom: 0;
  right: 0;
}
p.kinder-strip {
  margin: auto;
  width: max-content;
  min-width: 50%;
  padding: 5px;
  color: #fff;
}
.kinder-news {
  background: url(https://webapi.entab.info/api/image/NCSR/public/Images/kinder-news.png);
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-top: 190px;
  padding-bottom: 51px;
}
.kinder-news.senior {
  background: url(https://webapi.entab.info/api/image/NCSR/public/Images/news.png);
  background-size: 100% 100%;
  padding: 50px 20px;
  width: 80%;
  position: relative;
}
.kinder-news.senior .kinderNews {
  width: 74%;
  margin-bottom: 80px;
}
.kinder-news .kinderNews {
  width: 56%;
}
.kinder-news .kinderNews marquee {
  height: 320px;
}
.kinder-news .kinderNews marquee .d-flex {
  justify-content: space-between;
}
.kinder-news .kinderNews h2 {
  font-size: 25px;
}
.senior_news-blk {
  background: url(https://webapi.entab.info/api/image/NCSR/public/Images/news.png);
  background-size: 100% 100%;
}
.kinder-news.senior:before {
  content: "";
  width: 15%;
  height: 200px;
  position: absolute;
  left: 40px;
  bottom: 35px;
  background: url(https://webapi.entab.info/api/image/NCSR/public/Images/left-boy.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: bottom left;
}
.kinder-news.senior:after {
  content: "";
  width: 15%;
  height: 200px;
  position: absolute;
  right: 20px;
  bottom: 35px;
  background: url(https://webapi.entab.info/api/image/NCSR/public/Images/right-boy.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: bottom left;
}
.testimonial-blk {
  padding: 20px;
  background: var(--main-color-gradient);
  border: 1px solid var(--primary-color);
  width: 90%;
  border-radius: 15px;
  margin-top: 40px;
  margin-bottom: 40px;
}
.testimonial-blk img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin-right: 20px;
}
.testimonial-head {
  background: url(https://webapi.entab.info/api/image/NCSR/public/Images/quote.png);
  background-repeat: no-repeat;
  background-position: right center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
}
.testimonial-head p {
  margin-bottom: 0;
}
.testimonial-head h4 {
  text-transform: uppercase;
  font-weight: 800;
}
.video-blk {
  margin-top: 40px;
}
.video-blk h4 {
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 20px;
}
.testimonial .title {
  text-align: center;
  margin-bottom: 40px;
}
.video-blk p {
  background: #333;
  margin-top: 13px;
  padding: 6px 20px;
  text-align: center;
  color: #fff;
}
.testimonial-blk:hover {
  margin-bottom: 0;
}
.testimonial-blk:hover .testimonial-head {
  flex-direction: column;
  margin-top: -50px;
  text-align: center;
}
.testimonial-blk:hover .testimonial-head img {
  margin-right: 0;
}
.topperimages {
  background: url(https://webapi.entab.info/api/image/NCSR/public/Images/topper-border.png);
  background-size: 100% 100%;
  padding: 5px;
  width: 80%;
  margin: auto;
  position: relative;
  margin-top: 40px;
}
.topperimages img {
  border-radius: 50%;
}
.topperimages p {
  position: absolute;
  width: 50px;
  height: 50px;
  background: url(https://webapi.entab.info/api/image/NCSR/public/Images/percent.png);
  background-size: 100% 100%;
  font-size: 12px;
  top: -25px;
  left: -1px;
  transform: rotate(330deg);
  transition: 0.5s;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-weight: 800;
  line-height: 26px;
}

.topperdiv:hover .topperimages p {
  transform: translate(-50%, -101%) rotate(0deg);
  font-size: 18px;
  left: 50%;
  top: 0;
  width: 80px;
  height: 70px;
  padding-bottom: 10px;
}

.topperdiv:hover .topperimages p
.toopersec .title {
  text-align: center;
}
.topperdiv {
  width: 79%;
  text-align: center;
  margin-top: 60px;
  min-height: 310px;
}
.toopersec {
  position: relative;
}
.toopersec:before {
  content: "";
  width: 300px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: url(https://webapi.entab.info/api/image/NCSR/public/Images/topper-before.png);
  background-repeat: no-repeat;
  background-size: 100%;
}
.toopersec .topper12bx > div {
  width: 90%;
}
img.tpr-shape {
  margin-top: -16px;
  margin-bottom: 20px;
  width: 100%;
}
.toopersec:after {
  content: "";
  width: 200px;
  height: 80%;
  position: absolute;
  right: 0;
  bottom: 0;
  background: url(https://webapi.entab.info/api/image/NCSR/public/Images/topper-after.png);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
}
.homeeventboximg {
  background: #fff;
  width: 90%;
  padding: 10px;
  border-radius: 6px;
  margin-bottom: 20px;
  border: 1px solid #F065DE;
}
.toopersecbg,
.testimonial {
  padding: 60px 0
}
.homeeventboximg img {
  width: 100%;
  margin-bottom: 10px;
  height: 220px;
  object-fit: contain;
  background: #ddd;
}
.eventpgh p {
  font-size: 14px;
  margin-bottom: 7px;
}
.count-val p {
  margin-top: 5px;
  margin-bottom: 0;
}
.homeeventboximg h5 {
  min-height: 37px;
  font-size: 16px;
  text-align: center;
  margin: 20px 0;
  font-weight: 600;
  text-transform: uppercase;
}
.homeevent button.readMore {
  margin: auto;
  display: block;
  width: max-content;
  overflow: hidden;
}

.homeevent .title {
  text-align: center;
  margin-bottom: 20px;
}

.homeeventboximg .date-event {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  font-size: 14px;
}

.homeevent {
  background: url(https://webapi.entab.info/api/image/NCSR/public/Images/event-bg.png);
  background-size: 100% 100%;
  padding: 60px 0;
}

.homeeventboximg .date-event i {
  margin-right: 6px;
  vertical-align: top;
}


.event-blk {
  background: var(--main-color-gradient);
  padding: 30px;
  box-shadow: 50px 20px -50px #1e1e1e
}

.campus {
  font-size: 14px;
}

.social_media ul {
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.social_media ul li {
  margin: 5px;
}

.social_media ul li img {
  width: 32px;
}

img.img-fluid.ftr-logo {
  margin: auto;
  display: block;
}

.footer p {
  text-align: center;
}

.cont-dtl i {
  display: block;
  font-size: 22px;
  color: #8a0a79;
}

.button-ftr img {
  width: 90px;
}

.cont-dtl p {
  margin-bottom: 0;
}

.footer {
  background: url(https://webapi.entab.info/api/image/NCSR/public/Images/footer-bg.png);
  background-size: 100% 100%;
  padding: 90px 0 0;
}

.footer a {
  text-decoration: none;
  color: inherit;
}

.footer .quick-link ul {
  padding: 0;
  list-style: none;
}

.d-flex.footer-wrap iframe {
  width: 250px;
  height: 200px;
  border: 5px solid #fff;
  box-shadow: 0px 0px 5px 0px #ddd;
}

.cont-dtl h5 {
  text-align: center;
}

.mobile-app p:last-child {
  padding: 5px 20px;
  background: linear-gradient(180deg, #FFF5A7 0%, #FFB545 100%);
  width: max-content;
  margin: 10px auto;
  display: block;
}

.d-flex.mb-app {
  align-items: center;
  padding: 31px;
}

.d-flex.mb-app img.img-fluid.phone-img {
  width: 100px;
  margin-top: -50px;
}

.footer .quick-link ul li {
  margin-top: 5px;
}

.cont-dtl h5,
.quick-link h5 {
  font-weight: 800;
  color: #28579e;
}

.copyright p {
  margin-bottom: 0;
}

.copyright {

  background: linear-gradient(180deg, #28579E 0%, #14305A 100%);
  color: #fff
}

/* Inner Pages */
.border_box {
  padding: 14px;
  box-shadow: 0px 0px 5px 0px #ddd;
}

.breadcrumb {
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  min-height: 240px;
  background: url(https://webapi.entab.info/api/image/NCSR/public/Images/breadcrumb.jpg);
  background-size: 100%;
  position: relative;
}

.innerPage {
  padding: 60px 0;
}

.breadcrumb ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}

.breadcrumb ul li {
  padding-left: 10px;
}

.breadcrumb:before {
  content: "";
  width: 100%;
  height: 100%;
  background: #7aaee1db;
  position: absolute;
  left: 0;
  top: 0;
}

.breadcrumb h1,
.breadcrumb ul {
  position: relative;
  color: #fff;
  text-shadow: 0px 0px 8px #5050505e;
  text-transform: uppercase;
}

.breadcrumb ul {
  background: #1a1a1acf;
  font-size: 14px;
  padding: 7px 10px;
  border-radius: 5px 5px 0 0;
  margin-top: 19px;
}

.breadcrumb ul a {
  color: #fff;
  text-decoration: none;
}

.breadcrumb ul li:last-child {
  border-right: 0px;
}

/* Inner Pages */

ul.sub-menu {
  position: absolute;
  list-style: none;
  padding: 0;
  background: #fff;
  z-index: 9;
  border-radius: 0px 0px 5px 5px;
  box-shadow: 1px 4px 5px -2px #ddd;
  display: none;
}

ul.sub-menu li a {
  text-decoration: none;
  color: #000;
  font-weight: 400;
  font-size: 16px;
}

ul.sub-menu li {
  padding: 5px;
}

ul.navbar-nav>li:hover>ul.sub-menu {
  display: block;
}

ul.sub-menu li:hover>ul.sub-menu {
  left: 100%;
  top: 0;
  position: absolute;
}

ul.sub-menu li {
  position: relative;
}

ul.sub-menu li ul.sub-menu {
  display: none;
}

ul.sub-menu li:hover>ul.sub-menu,
ul.sub-menu li:hover ul.sub-menu>li {
  display: block;
  width: max-content;
}

ul.innerlist li:before {
  position: absolute;
  content: ' \F26E ';
  font-family: 'bootstrap-icons';
  left: -24px;
  top: 0;
  font-size: 19px;
  color: #00597e;
}

.innerPage .midimg {
  border: 6px solid #fff;
  margin-bottom: 20px;
  box-shadow: 0px 0px 5px 0px #c1c1c1;
}

.innerPage h3 {
  color: #28579e;
}

table.table td {
  border: 1px solid #ddd;
}

table.table thead {
  background: var(--main-color);
  color: #fff;
}

table.table thead th {
  font-weight: 400;
}

table.table td {
  border: 1px solid #ddd;
}

table.table thead {
  background: var(--main-color);
  color: #fff;
}

table.table thead th {
  font-weight: 400;
}

.tab-selection-list .tabs-select {
  padding: 10px 10px;
  margin: 6px;
  text-decoration: none;
  border: 1px solid;
  border-radius: 5px;
  min-width: 155px;
  display: inline-block;
  text-align: center;
  color: var(--main-color);
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 30px;
}

.tab-selection-list {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
}

.tab-selection-list .tabs-select.active {
  background: var(--main-color);
  color: #fff;
  font-weight: 400;
}

ul.sports-tab {
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

ul.sports-tab li {
  background: #ddd;
  padding: 9px 10px;
  margin: 8px;
  min-width: 170px;
  border-radius: 5px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
}

ul.sports-tab li.active {
  background: var(--primary-color-gradient);
  color: #fff;
  font-weight: 400;
}

.contactinfobox {
  display: flex;
  background: #ededed;
  padding: 14px 20px 0;
  margin: 0px 0 20px;
  border-radius: 5px;
  border-left: 4px solid #c121ac;
}

.contactinfobox a {
  text-decoration: none;
  color: #000;
}

.contactinfobox i {
  font-size: 24px;
  margin-right: 20px;
  color: var(--main-color);
}

.contactUs form input,
.contactUs form textarea {
  width: 100%;
  display: block;
  border: 1px solid #ddd;
  padding: 7px;
  border-radius: 5px;
  margin-bottom: 14px;
  margin-top: 5px;
}

.contactUs form label {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
}

.contactUs form input[type="submit"] {
  width: max-content;
  padding: 6px 20px;
  margin: auto;
  margin-top: 20px;
  background: var(--main-color);
  color: #fff;
  border: 0px solid #fff;
}

.contactUs form {
  padding: 30px;
  background: linear-gradient(0deg, #19396a5e, #76a0e15e);
  border-radius: 13px;
}

.assistance {
  background: #dddddd63;
  padding: 20px;
  border-radius: 5px;
}
.homeslider{
  position: relative;
}
.OnlineR {
  position: absolute;
  right: 0;
  bottom: 19%;
  background: var(--main-color);
  color: #fff;
  padding: 5px 20px;
  text-transform: uppercase;
  vertical-align: middle;
  border-radius: 3px;
  animation: 2s blinker infinite alternate;
  transform: translate(-0%, -0%);
  box-shadow: 0px 0px 10px 0px #000;
  max-width: 256px;
  border: 2px solid #fff;
  border-radius: 5px;

}
@keyframes blinker{
  0%{
      background: #c29414;        
  }
  100%{
      background: #c121ac;
  }
}
.erpboxdescpopup {
  display: flex;
}

.erppopup {
  text-align: center;
}

.erppopup img {
  margin: auto;
}

.erpboximg {
  text-align: center;
}

.erpboxdesc a {
  text-decoration: none;
  color: #d828c1;
}

.innerPage.cbse-block .col {
  text-align: center;
}

.innerPage.cbse-block .col img {
  height: 70px;
  margin-bottom: 14px;
}

.innerPage.cbse-block .col p {
  text-transform: uppercase;
  color: #28579e;
  font-weight: 600;
}

.innerPage.cbse-block .col>div {
  background: #f1f1f1;
  padding: 24px 0;
  box-shadow: inset 0px 0px 15px 10px #ddd;
  border-radius: 7px;
}

.innerPage.cbse-block .col>div a {
  text-decoration: none;
}

.innerPage.cbse-block .col>div p {
  margin-bottom: 0;
}

input#btnshwtcdetails {
  background: var(--main-color);
  color: #fff;
  margin-top: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.text_box_sec.form-group {
  margin-bottom: 20px;
}

.text_box_sec.form-group label {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
}

h6.campus-head {
  text-align: center;
  margin-bottom: 30px;
}

.row.tabs-dynamic {
  background: #e7e7e7;
  padding: 12px 0;
  margin-bottom: 30px;
}

.row.tabs-dynamic input,
.row.tabs-dynamic select {
  width: 100%;
  padding: 8px;
  border: 1px solid #b9b9b9a1;
  border-radius: 5px;
}

.eventbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  box-shadow: 0px 0px 5px 0px #ddd;
  border-radius: 5px;
  overflow: hidden;
}

.eventbox .eventboximg {
  width: 29%;
}

.eventbox .eventboxdesc {
  width: 67%;
  padding-right: 15px;
}

.eventbox .eventboximg img {
  width: 100%;
  height: 160px;
  object-fit: contain;
  background: #ededed;
}
.eventbox .eventdate span {
  font-size: 14px;
}
.eventbox .eventdate {
  display: flex;
  justify-content: space-between;
}
.eventbox .eventdate i {
  margin-right: 5px;
}
.innerPage .news-blk {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  box-shadow: 0px 0px 5px 0px #ddd;
}
.news-blk .news-head {
  width: 13%;
}
.news-blk .news-content {
  width: 84%;
}
.news-blk .attachment {
  position: absolute;
  top: 0;
  right: 0;
  background: var(--primary-color-gradient);
  color: #fff;
  padding: 3px 7px;
}
.news-blk .attachment a {
  color: #fff
}
.news-blk .news-head {
  text-align: center;
  background: linear-gradient(180deg, #28579E 0%, #14305A 100%);
  color: #fff;
  padding: 27px 5px;
}
.news-blk .news-head span {
  display: block;
}
.news-blk .news-content h3 {
  font-size: 21px;
}
.news-blk .news-content p {
  margin-bottom: 0;
  font-size: 14px;
}
.news-blk .news-head h2 {
  font-size: 24px;
}
.magnify-modal {
  position: fixed !important;
  z-index: 999999999 !important;
  padding-top: 10px !important;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  overflow: auto !important;
  background-color: rgba(0, 0, 0, 0.8509803922) !important
}
.magnify-header .magnify-toolbar {
  float: right;
  position: relative;
  z-index: 9
}
.magnify-stage {
  position: absolute;
  top: 40px;
  right: 10px;
  bottom: 40px;
  left: 10px;
  z-index: 1;
  background: #0000000a;
  overflow: hidden
}
.magnify-image {
  position: relative;
  display: inline-block;
  border: 5px solid #fff
}
.magnify-image.image-ready {
  max-width: 100%;
  max-height: 100%
}
.magnify-footer {
  position: absolute;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 40px;
  color: #fff;
  text-align: center
}
.magnify-footer .magnify-toolbar {
  display: inline-block
}
.magnify-button {
  display: inline-block;
  width: 40px;
  height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  border-width: 0;
  border-radius: 0;
  cursor: pointer;
  outline: none;
  background: #022939;
}
.magnify-button:hover {
  color: #fff;
  background-color: #e00000
}
.magnify-button-close:hover {
  background-color: #e00000
}
.magnify-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.3);
  color: #333
}
.magnify-loader::before {
  content: '';
  display: inline-block;
  position: relative;
  width: 36px;
  height: 36px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  -webkit-animation: magnifyLoading 1s infinite linear;
  animation: magnifyLoading 1s infinite linear
}
.magnify-loader::after {
  content: '';
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  overflow: hidden
}
@-webkit-keyframes magnifyLoading {
  0% {
    -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
    transform: rotateZ(0deg) translate3d(0, 0, 0)
  }

  100% {
    -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
    transform: rotateZ(360deg) translate3d(0, 0, 0)
  }
}
@keyframes magnifyLoading {
  0% {
    -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
    transform: rotateZ(0deg) translate3d(0, 0, 0)
  }

  100% {
    -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
    transform: rotateZ(360deg) translate3d(0, 0, 0)
  }
}
.abt-section {
  padding: 50px 0;
}
button.read-more {
  background: var(--main-color);
  color: #fff;
  text-transform: uppercase;
  padding: 7px 20px 5px;
  border: 0px;
  vertical-align: middle;
  border-radius: 4px;
  letter-spacing: 1px;
  margin-top: 20px;
}
.kinderNews marquee {
  min-height: 362px;
}
.evt-news .title {
  margin: 30px 0;
}
.evt-news {
  padding: 50px 0;
}
.goal-rules h2 {
  font-size: 19px;
  text-align: center;
  margin-bottom: 20px;
}
.goal-rules {
  background: #fff;
  border: 5px solid #f3f3f3;
  padding: 20px;
  height: 96%;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px #ddd;
}
.goal-rules p,
.goal-rules ul {
  margin-bottom: 0;
}
.min-height-section img {
  height: 300px;
  object-fit: contain;
  width: 100%;
  background: #ddd
}
.kidsuniform_box h6 {
  line-height: 25px;
}
.circular-activity.tabClick1, .classroom-activity.tabClick1 {
  background: #ddd;
  padding: 9px 11px;
  text-transform: uppercase;
  margin: 10px;
  border: 0;
  min-width: 230px;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 20px;
  border-radius: 5px;
}
.button-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.button-list .active {
  background: linear-gradient(0deg, #ab1597, #cf22b8);
  color: #fff;
}
.kidsuniform .kidsZone {
  background: var(--main-color);
  padding: 20px;
  color: #fff;
}
.kidsuniform .kidsZone h6 {
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
}
ul.list-view li:before {
  content: "";
  width: 25px;
  height: 25px;
  background: url(https://webapi.entab.info/api/image/NCSR/public/Images/pointer.png);
  background-size: 100%;
  position: absolute;
  background-repeat: no-repeat;
  left: 0;
  top: 0;
}
ul.list-view {
  padding: 0;
}
ul.list-view li {
  margin-top: 10px;
  padding-left: 30px;
  list-style: none;
  position: relative;
}
.kinderSection .bg-wt {
  background: url(https://webapi.entab.info/api/image/NCSR/public/Images/cloud.png);
  background-size: 100% 100%;
  filter: drop-shadow(0px 0px 2px #ddd);
  box-shadow: none;
  padding: 60px 0;
}
.kinderSection.flex-block>div::before,
.kinderSection .bg-wt:before {
  display: none;
}
.kinderSection.flex-block {
  margin-top: -110px;
}
.kinderSection .bg-wt:hover h5 {
  color: #c121ac;
}
div#modalH button.btn-close {
  z-index: 9;
  position: absolute;
  top: 10px;
  right: 10px;
}
div#modalH .modal-body .card {
  border: 0px solid !important;
}
div#modalH .modal-body .card h1 {
  font-size: 21px;
}
div#modalH .modal-body .card .card-body {
  padding: 0 7px;
  text-align: center;
}
div#modalH .modal-body .card .card-body a {
  background: var(--primary-color);
  border: 0px;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 0;
  border-radius: 5px;
  overflow: hidden;
}
div#modalH .modal-body .card .card-body a span {
  display: inline-block;
  line-height: 31px;
  padding: 5px 10px;
}
.popup .modal-dialog.modal-dialog-centered {
  min-width: 680px;
}
.highlights marquee a:hover{
  color: inherit;
}
.highlights marquee .d-flex a.clk-here {
  margin-top: 10px;
  background: var(--primary-color-gradient);
  color: #fff;
  padding: 9px;
  border-radius: 5px;
}
.admission-block {
  width: 440px;
  position: fixed;
  z-index: 99;
  background: #fff;
  padding: 30px 20px 10px;
  top: 50%;
  right: 0;
  transform: translate(-0%, -50%);
  box-shadow: 0px 0px 5px 0px #ddd;
  border-radius: 15px;
  max-width: 95%;
}
.admission-block form label {
  width: 100%;
  display: block;
  text-transform: uppercase;
  letter-spacing: 0px;
  font-weight: 800;
  font-size: 14px;
}
.admission-block form input, .admission-block form select {
  width: 99%;
  border: 1px solid #ddd;
}
.admission-block form > div {
  margin-bottom: 13px;
}
.admission-block form input, .admission-block form textarea, .admission-block form select {
  margin-top: 5px;
  padding: 8px;
  border-radius: 5px;
}
.admission-block form textarea {
  border: 1px solid #ddd;
  width: 99%;
}
.admission-block form input[type="submit"] {
  border-radius: 15px;
  overflow: hidden;
  background: var(--main-color);
  color: #fff;
}
.admission-block h3 {
  margin-bottom: 25px;
}
.admission-block .close {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 34px;
  line-height: 20px;
  background: var(--primary-color);
  color: #fff;
  cursor: pointer;
}
.admission-form {
  position: fixed;
  top: 50%;
  transform: translate(0%, -50%);
  z-index: 99;
  right: 0;
  background: #ddd;
  padding: 8px 20px;
  font-size: 20px;
  text-align: center;
  border-radius: 5px 0 0 5px; 
  color: #fff;
  transition: 0.5s;
  box-shadow: -11px 15px 10px -9px #787878;
  cursor: pointer;
}
.admission-form.blinker {
  animation: 2s chngColor infinite alternate;
}
@keyframes chngColor{
  0%{
    background: #66a0da;
  }
  100%{
    background: #dbc456;
  }
}
.feedback {
  text-align: center;
  margin-top: 20px;
  color: green;
  text-transform: uppercase;
}
.highlights marquee .d-flex a.url-highlight {
  color: #fff;
  margin-top: 20px;
  text-transform: uppercase;
  background: var(--primary-color-gradient);
  padding: 4px 6px 2px;
  border-radius: 6px;
} 

.header .social_media {
  position: fixed;
  top: 0;
  right: 0;
  width: max-content;
  max-width: 90%;
}

.header .social_media ul {
  flex-direction: column;
}
.topperdesc h5{
  font-size: 20px;
}
.zoomIn img{
  animation: 2s scale infinite alternate;
}
.zoomIn .slick-slide{
  overflow: hidden;
}
.admission-block form > div span{
  color: red;
}
@keyframes scale{
  0%{
    transform: scale(1);
  }
  100%{
    transform: scale(1.05);
  }
}
.innerPage .admissionimg{
  width: 100%;
}
.innerPage h6 span {
  color: #8a0a79;
  font-weight: 800;
}
.video-blk iframe {
  width: 100%;
}
img.img-fluid.kinder_school {
  box-shadow: 0px 40px 20px -30px #0000003d, 0px -40px 20px -30px #0000003d;
  margin-top: 50px;
  border: 13px solid #fff;
}
.sub_gallery.row img {
  width: 100%;
  height: 300px;
  object-fit: contain;
  background: #ededed;
  box-shadow: 0px 0px 5px 0px #00000038;
  border: 6px solid #fff;
  border-radius: 5px;
}
.row.kidsactivities_sec_img.min-height-section {}

.row.kidsactivities_sec_img.min-height-section .gal_new {
    position: relative;
    padding: 10px;
    background: #f1f1f1;
    box-shadow: 0px 0px 5px #00000042;
    border: 5px solid #fff;
    border-radius: 6px;
}
.row.kidsactivities_sec_img.min-height-section .gal_new p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
.row.kidsactivities_sec_img.min-height-section .gal_new img {
    height: 210px;
    margin-bottom: 17px;
}

.row.kidsactivities_sec_img.min-height-section .gal_new .total-img {
    position: absolute;
    top: 0;
    padding: 0px 10px;
    left: 0;
    background: #1d4178;
    color: #fff;
} 
.col-xxl-3.col-xl-3.col-lg-4.col-md-6.col-12.galleryCount{
  margin-bottom: 20px;
}
.row.kidsactivities_sec_img.min-height-section .gal_new .total-img p {
    margin-bottom: 0;
}

.row.kidsactivities_sec_img.min-height-section .gal_new h6.date span {
    float: right;
    font-size: 20px;
}
.topper_page img {
width: 100%;
object-fit: contain;
    height: 250px;
    background: #fff;
    margin-bottom: 20px;
}
.topper-service {
  margin-bottom: 20px;
  padding: 10px;
  border: 5px solid #fff;
  box-shadow: 0px 0px 17px -3px #00000045;
  background: #eaf3ff;
  border-radius: 5px;
}

.topper-service .details.clearfix h4 {
  font-size: 17px;
}

.topper-service .details.clearfix ul {
  padding: 0;
  list-style: none;
  color: #d828c1;
}
.col-xl-3.col-lg-6.col-md-6.divider {
  display: flex;
}
.quick-link {
  width: 50%;
}

.cont-dtl {
  width: 100%;
}
a.phelp {
  background: #000;
  color: #fff;
  padding: 9px 5px 6px 5px;
  font-size: 12px;
  border-radius: 4px;
  line-height: 11px;
  width: max-content;
  height: max-content;
  margin-left: 4px;
}
.mobile-app img {
  width: 90px;
}
.d-flex.mb-app .mobile-app {
  width: 70%;
}
.mobile-app p {
  font-size: 12px;
}
.mobile-app h6 {
  text-align: center;
}
.footerbtm .mobile-app {
  width: 100% !important;
  justify-content: center;
  display: flex;
}
.guideheading i {
  font-size: 6px;
}
.mobileappimg img{
  width: 310px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.magazine_blk {
  box-shadow: 0px 0px 11px 1px #b9b9b9;
  border: 5px solid #fff;
  border-radius: 5px;
}

.magazine_blk button.mt-4 {
  margin: auto;
  display: block;
  margin-bottom: 10px;
  padding: 4px 10px 0px;
  text-transform: uppercase;
  background: #c121ac;
  border: 0px;
  color: #fff;
  letter-spacing: 2px;
  border-radius: 4px;
}

/* Flip Book  */
.stf__block img {
  width: 100%;
}
.flipOpen > div {
  margin: auto;
  position: relative; 
}
.flipOpen {
  margin-top: 40px; 
}
.navigation-buttons button {
  padding: 0;
  margin: 10px;
  background: #ad0807;
  border: 0;
  font-size: 26px;
  color: #fff;
  /* margin: auto; */
  line-height: 0;
  padding: 3px 14px;
  margin-bottom: 30px;
}

.navigation-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
/* Flip Book  */

.career-form input {
  margin: 0px 0 30px;
  border: 0;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  padding: 0;
}

.career-form h3 {
  font-size: 22px;
  margin-bottom: 30px;
}

.career-form h3 span {
  /* float: right; */
  /* background: #000; */
  font-weight: 800;
  font-size: 31px;
  margin-left: 5px;
}
.career-form {
  margin-top: 70px;
}
button.removebtn {
  position: absolute;
  top: -26px;
  right: 0;
  border: 0;
  background: red;
  color: #fff;
  padding: 0px 10px;
  font-size: 29px;
  border-radius: 50%;
  line-height: 1;
}

.row.pos-rel {
  position: relative;
}
.career-form .error {
  color: red;
  margin-bottom: 20px;
  margin-top: -19px;
}
form.careerform-feilds img {
  width: 75%;
  height: 120px;
  object-fit: contain;
  margin: auto;
  display: block;
  background: #ddd;
  padding: 6px;
}
.career-form input[type='submit'] {
  width: max-content;
  padding: 6px 20px !important;
  margin: auto;
  margin-top: 20px !important;
  background: var(--main-color) !important;
  color: #fff !important;
  border: 0px solid #fff;
  margin: auto;
  display: block;
  margin-top: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 20px;
  border-radius: 5px;}
  .success-message {
    background: green;
    color: #fff;
    padding: 5px;
    margin-top: 20px;
    border-radius: 5px;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.careerform-feilds input::placeholder{
  text-transform: uppercase;
}
.career-form input[type='date'] {
  text-transform: uppercase;
}
.career-form input[type='date'] {
  text-transform: uppercase;
}

.col-lg-12.mt-4.app-form {
  background: #eef7ff;
  padding: 40px;
  border-radius: 5px;
}


.app-form input {
  background: transparent;
  border: 1px solid #a1a1a1;
  padding: 4px 7px;
  border-radius: 5px;
}
form.careerform-feilds img {
  width: 75%;
  height: 150px;
  object-fit: contain;
  margin: auto;
  display: block;
  background: #ddd;
  padding: 6px;
  margin-bottom: 30px;
}

input.form-control.img-option {
  width: 100%;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  border: 0;
  opacity: 0;
}

.pos-rel {
  position: relative;
}
.career-form  label {
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0px;
}

.col-lg-12.mt-4.app-form input:focus {
  border: 2px solid #3a77d5;
  outline: none;
  box-shadow: 0px 0px 5px 0px #ffffff;
}
.success-message p {
    margin-bottom: 0;
    text-align: center;
}
@media(max-width: 1400px) {
  .quick-link {
    width: 100%;
}
  .topperimages {
    width: 66%;
  }
  ul.navbar-nav li {
    margin: 0
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    font-size: 13px
  }
  .kinder-news .kinderNews marquee {
    height: 240px;
  }
  .button-ftr img {
    width: 130px;
  }
  .dark-box>div {
    padding: 13px 10px;
    margin: 0 8px;
    min-width: 123px;
  }
  .flex-block>div {
    min-width: 162px;
  }
  .d-flex.footer-wrap iframe {
    display: none;
  }
  div#carouselExampleControls img {
    height: 460px;
  }
  .bg-wt {
    padding: 18px 0;
  }
}

@media(max-width: 1300px) {
  ul.navbar-nav li img {
    display: none;
}

ul.navbar-nav li {
    margin: 9px;
}
  .dark-box>div p {
    font-size: 13px;
  }
  .header_list ul li {
    margin: 8px 10px;
    font-size: 17px;
}
  .dark-box>div {
    min-width: 97px;
  }
  .highlights {
    padding: 19px;
  }
  .pphelp {
    position: fixed;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 10px 2px;
    font-size: 13px;
  }
}

@media all and (min-width: 992px) {
  .navbar .dropdown-menu-end {
    right: 0;
    left: auto;
  }
  .navbar .nav-item .dropdown-menu {
    padding: 0;
    border-radius: 2px;
    display: block;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
    margin-top: 0;
  }
  .navbar .dropdown-menu.fade-down {
    top: 80%;
    transform: rotateX(-75deg);
    transform-origin: 0% 0%;
  }
  .navbar .dropdown-menu.fade-up {
    top: 180%;
  }
  .navbar .nav-item:hover .dropdown-menu {
    transition: .3s;
    opacity: 1;
    visibility: visible;
    top: 100%;
    transform: rotateX(0deg);
  }
}

@media (max-width: 991px) {
  nav.navbar.navbar-expand-lg button.navbar-toggler {
    position: absolute;
    background: #fff;
    top: -56px;
  }
  .navbar-collapse {padding-top: 10px;padding-bottom: 10px;position: absolute;top: 0;background: #005c9d;width: 100%;z-index: 9;left: 0;padding-left: 20px;  }
  nav.navbar.navbar-expand-lg {width: 100%;padding-left: 0;display: flex;justify-content: flex-end;padding-right: 10px;  }
}

@media(max-width: 768px) {
  .popup .modal-dialog.modal-dialog-centered {min-width: 95%;}
  div#carouselExampleControls img {height: auto;}
  .OnlineR {position: fixed;bottom: 0;z-index: 999;width: 100%;text-align: center;}
  .kinderSection.flex-block {
    margin-top: 0;
}
.dark-box img {
  display: none;
}

.dark-box p {
  margin-bottom: 0;
}

.dark-box>div {
  padding: 5px;
  min-width: max-content;
  margin: 0 3px;
}

}

@media(max-width: 567px) {
  
  .flipOpen, .stf__item, .flipOpen > div {
    width: 100% !important; 
    min-width: 100% !important;

  }
  .stf__item{

    left: 0 !important;
  }
  .flipOpen{
    position: relative;
    height: 400px !important;  
    max-height: 400px !important;  
    min-height: 400px !important;  
    overflow: hidden;
    ;
  }  
.d-flex.mb-app .mobile-app {
  width: 100%;
}
}

@media(max-width: 400px) {
  .kinderSection .bg-wt {
  
    padding: 30px 0;
}
  nav.navbar.navbar-expand-lg button.navbar-toggler {
    top: -50px;
  }

}